import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import Gallery from './views/Gallery';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-G5ZZFWBG5S"; // Replace with your GA4 ID

ReactGA.initialize(TRACKING_ID);

class App extends React.Component {



  componentDidMount() {
     // Set page view on first load
     this.trackPageView(this.props.location.pathname);

     // Initialize ScrollReveal
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    // Track page views when route changes
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.trackPageView(this.props.location.pathname);
      this.refs.scrollReveal.init();
    }
  }

  trackPageView = (pathname) => {
    ReactGA.send({ hitType: "pageview", page: pathname });
    console.log("Tracking page:", pathname); // Debugging
  };

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
              <AppRoute exact path="/gallery" component={Gallery} layout={LayoutDefault} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);