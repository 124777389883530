import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
// import ReactPlayer from 'react-player';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: "Works we 've done",
      paragraph: 'Watch some of the events we have covered'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>


                          <div className="split-item">
                {/* <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Funeral Ceremony
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                   The funeral ceremony of Mr Edward Ayeebo Asumda
                  </p>
                </div> */}
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div className="relative">
                   
                    <iframe
                      class="responsive-iframe"
                      src="https://player.vimeo.com/video/859745950?h=c7a94684b1"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      width={528}
                      height={396}
                      title="Sequence 01_1.mp4.mp4">
                      
                      </iframe>{" "}
                  </div>
                  
                </div>
              </div>


                          <div className="split-item">
                {/* <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Funeral Ceremony
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                   The funeral ceremony of Mr Edward Ayeebo Asumda
                  </p>
                </div> */}
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div className="relative">
                   
                    <iframe
                      class="responsive-iframe"
                      src="https://www.youtube.com/embed/eoYz4K0eYe8"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      width={528}
                      height={396}
                      title="Sequence 01_1.mp4.mp4">
                      
                      </iframe>{" "}
                  </div>
                  
                </div>
              </div>


                            <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Shai on behind the scenes
                  </h3>
            
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image 
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div  className="relative">
                    <iframe
                      class="responsive-iframe"
                      src="https://www.youtube.com/embed/PaMJQ92vw8U"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div>



                 <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                   Meca Melody on behind the scenes
                  </h3>
                  {/* <p className="m-0 reveal-from-left" data-reveal-delay="400">
                   We were represented at Davido Album Launch & Concert in London 2015
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div className="relative">
                    {/* <Image
                      src={require('./../../assets/images/features-split-top-03.png')}
                      alt="Features split top 03"
                      width={528}
                      height={396} /> */}
                                  <iframe
                                    class="responsive-iframe"
                      src="https://www.youtube.com/embed/3F5yNKulqAw"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                                      title="video"
                                      width={528}
                                    height={396} 
                    />{" "}
                  </div>
                </div>
              </div>



              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Smade Afrobeat Easter Fiesta
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  This is Smade Afrobeat Easter Fiesta covered by us
                  </p>
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image 
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div  className="relative">
                    <iframe
                      class="responsive-iframe"
                      src="https://www.youtube.com/embed/Bh267GKcXgM"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Daniel Arieleno
                  </h3>
                  {/* <p className="m-0 reveal-from-left" data-reveal-delay="400">
                   We were represented at Davido Album Launch & Concert in London 2015
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div className="relative">
                    {/* <Image
                      src={require('./../../assets/images/features-split-top-03.png')}
                      alt="Features split top 03"
                      width={528}
                      height={396} /> */}
                                  <iframe
                                    class="responsive-iframe"
                      src="https://www.youtube.com/embed/aefopvbMbOo"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                                      title="video"
                                      width={528}
                                    height={396} 
                    />{" "}
                  </div>
                </div>
              </div>


                 <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Africa Fashion Week London 2022
                  </h3>
                  {/* <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  This is Smade Afrobeat Easter Fiesta covered by us
                  </p> */}
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div className="relative" >
                    <iframe 
                       class="responsive-iframe"
        src="https://www.youtube.com/embed/6Ap9oo-TWwM"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div>



              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                 Olamide Concert in London  
                  </h3>
                  {/* <p className="m-0 reveal-from-left" data-reveal-delay="400">
                       This is another of Davido concert covered by our team.
                  </p> */}
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-02 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                    
                  <div className="relative" >
                    <iframe
                      class="responsive-iframe"
                      src="https://www.youtube.com/embed/8W-E53HxsmY"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div>





               <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Traditional Wedding
                  </h3>
                  {/* <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  This is Smade Afrobeat Easter Fiesta covered by us
                  </p> */}
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div className="relative" >
                    <iframe 
                       class="responsive-iframe"
        src="https://player.vimeo.com/video/734991152?h=262b3413a8"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div>
              


                    <div className="split-item">
                {/* <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Olamide Concert in London
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  This is Smade Afrobeat Easter Fiesta covered by us
                  </p>
                 
                </div> */}
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div className="relative" >
                    <iframe 
                       class="responsive-iframe"
                      src="https://player.vimeo.com/video/726556031?h=fe33148802&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                                      title="video"
                                      width={528}
                                    height={396} 
                    />{" "}
                   
                  </div>
                </div>
              </div>


{/* 
                 <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Davido Concert in 
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  This is another of Davido concert covered by our team
                  </p>
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div className=" relative">
                    <iframe
                      class="responsive-iframe"
                      src="https://www.youtube.com/embed/W1Flo1iv6Qc"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                        title="video"
                         width={528}
                      height={396} 
      />{" "}
                   
                  </div>
                </div>
              </div> */}


            </div>
          </div>
        </div>
      </section>
    );
  }
}

// const img01Style = {
//   position: 'absolute',
//   bottom: '-12.4%'
// }

// const img02Style = {
//   position: 'absolute',
//   bottom: '-13.1%'
// }

// const img03Style = {
//   position: 'absolute',
//   bottom: '-16.1%',
//   left: '-10.1%',
//   right: '10.1%'
// }

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;